import { stringify as uuidStringify } from 'uuid';

import { type ZoomUser } from '@lp-lib/api-service-client/public';

import config from '../../../src/config';
import { getLogger } from '../../../src/logger/logger';

const log = getLogger().scoped('zoom');

export type ZoomEnv = {
  user: ZoomUser;
  mock?: boolean;
};

export function setZoomEnv(user: ZoomUser) {
  const val = JSON.stringify({ user });
  log.info('setZoomEnv', { val });
  localStorage.setItem('zoomEnv', val);
}

export function getZoomEnv() {
  const val = localStorage.getItem('zoomEnv');
  if (!val) return;
  try {
    return JSON.parse(val) as ZoomEnv;
  } catch (error) {
    log.error('parse zoom env failed', error, { val });
    return;
  }
}

export function getZoomOAuthRedirectURL(baseUrl = config.app.baseUrl) {
  return `${baseUrl}/zoom/oauth`;
}

// X-Zoom-App-Context header that is sent with every HomeURL request when the
// app is being loaded and contains encrypted data that allows backend
// validation.
export function getZoomAppContext(request: Request) {
  return request.headers.get('x-zoom-app-context');
}

export function hasZoomUserAgent(request: Request) {
  // Mozilla/5.0 ZoomWebKit/537.36 (KHTML, like Gecko) ZoomApps/1.0
  return request.headers.get('user-agent')?.includes('ZoomApps');
}

export function requestInZoom(request: Request) {
  return !!getZoomAppContext(request) || hasZoomUserAgent(request);
}

// Zoom meeting UUIDs are base64 encoded UUIDs, which can include slashes.
// The slash is not friendly to the path in Firebase.
export function parseZoomMeetingUUID(meetingUUID: string) {
  const buf = Buffer.from(meetingUUID, 'base64');
  return uuidStringify(buf);
}

export function safeParseContextAction(action: string) {
  if (!action) return null;
  try {
    const parsed = JSON.parse(action);
    if (!parsed) return null;
    if (typeof parsed === 'object') return parsed;
    return null;
  } catch (error) {
    return null;
  }
}
