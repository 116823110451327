import zoomSdk, {
  type ExpandAppOptions,
  type RunningContext,
} from '@zoom/appssdk';
import { proxy } from 'valtio';

import { getLogger } from '../../../src/logger/logger';
import {
  markSnapshottable,
  type ValtioSnapshottable,
} from '../../../src/utils/valtio';
import { parseZoomMeetingUUID } from './utils';

type State = {
  inited: number;
  runningContext?: RunningContext;
  meetingId?: string;
  authStatus?:
    | 'authorized'
    | 'unauthorized'
    | 'unauthenticated'
    | 'authenticated';
  numOfParticipants: number;
  unsupportedApis: string[];
};

export interface IZoomClient {
  init(): Promise<void>;
  authorize(codeChallenge: string, state?: string): Promise<void>;
  promptAuthorize(onError?: (error: unknown) => void): Promise<void>;
  startCollaborate(): Promise<void>;
  openUrl(url: string): Promise<void>;
  expandApp(action: ExpandAppOptions['action']): Promise<void>;
  launchAppInMeeting(): Promise<void>;
  readonly state: Readonly<ValtioSnapshottable<State>>;
}

export class ZoomClient implements IZoomClient {
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  constructor(
    readonly sdk = zoomSdk,
    readonly log = getLogger().scoped('zoom')
  ) {}

  async init() {
    try {
      const resp = await this.config();
      this.log.info('Zoom SDK configured', resp);
      this._state.runningContext = resp.runningContext;
      this._state.authStatus = resp.auth.status;
      this._state.unsupportedApis = resp.unsupportedApis;
      await this.updateMeetingId();
      this.sdk.onRunningContextChange(async (event) => {
        this.log.info('Zoom running context change', { event });
        this._state.runningContext = event.runningContext;
        await this.updateMeetingId();
      });
      this.sdk.onMyUserContextChange(async () => {
        const userContext = await this.sdk.getUserContext();
        if (userContext.status !== this._state.authStatus) {
          this._state.authStatus = userContext.status;
          this.log.info('Zoom auth status change, trigger reconfig', {
            userContext,
          });
          const resp = await this.config();
          this.log.info('Zoom SDK reconfigured', resp);
          this._state.unsupportedApis = resp.unsupportedApis;
          this._state.inited = Date.now();
        }
      });
      this.updateNumOfParticipants();
      this.sdk.onParticipantChange(async () => {
        await this.updateNumOfParticipants();
      });
      this.sdk.onCollaborateChange(async (event) => {
        this.log.info('Zoom collaborate change', { event });
        if (event.action === 'end' || event.action === 'leave') {
          try {
            const resp = await this.sdk.closeApp();
            this.log.info('Zoom SDK closeApp', { resp });
          } catch (error) {
            this.log.error('Zoom SDK closeApp failed', error);
          }
          try {
            if (this._state.unsupportedApis.includes('endCollaborateSidecar')) {
              this.log.warn('Zoom SDK endCollaborateSidecar is unsupported');
              return;
            }
            const resp = await this.sdk.endCollaborateSidecar();
            this.log.info('Zoom SDK endCollaborateSidecar', { resp });
          } catch (error) {
            this.log.error('Zoom SDK endCollaborateSidecar failed', error);
          }
        }
      });
      this._state.inited = Date.now();
      this.log.info('Zoom SDK inited');
    } catch (error) {
      this.log.error('Zoom SDK init failed', error);
      throw error;
    }
  }

  private async config() {
    const resp = await this.sdk.config({
      capabilities: [
        'authorize',
        'promptAuthorize',
        'expandApp',
        'launchAppInMeeting',
        'startCollaborate',
        'joinCollaborate',
        'getMeetingUUID',
        'openUrl',
        'getUserContext',
        'getMeetingParticipants',
        'closeApp',
        'endCollaborateSidecar',

        'onAuthorized',
        'onRunningContextChange',
        'onMyUserContextChange',
        'onParticipantChange',
        'onCollaborateChange',
      ],
    });
    return resp;
  }

  async authorize(codeChallenge: string, state?: string) {
    try {
      const resp = await this.sdk.authorize({
        codeChallenge,
        state,
      });
      this.log.info('Zoom SDK authorize', { resp });
    } catch (error) {
      this.log.error('Zoom SDK authorize failed', error);
    }
  }

  async promptAuthorize(onError?: (error: unknown) => void) {
    try {
      const resp = await this.sdk.promptAuthorize();
      this.log.info('Zoom SDK promptAuthorize', { resp });
    } catch (error) {
      this.log.error('Zoom SDK promptAuthorize failed', error);
      onError?.(error);
    }
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  private async updateMeetingId() {
    if (
      this._state.runningContext === 'inMeeting' ||
      this._state.runningContext === 'inCollaborate'
    ) {
      const resp = await this.sdk.getMeetingUUID();
      this.log.info('Zoom SDK getMeetingUUID', { resp });
      try {
        this._state.meetingId = parseZoomMeetingUUID(resp.meetingUUID);
        this.log.info('Zoom parsed meetingId', {
          meetingId: this._state.meetingId,
        });
      } catch (err) {
        this.log.error('Zoom failed to parse meetingId', err);
        this._state.meetingId = resp.meetingUUID;
      }
    }
  }

  private async updateNumOfParticipants() {
    try {
      if (!this._state.meetingId) {
        this.log.warn(
          'Zoom SDK getMeetingParticipants, meetingId not available'
        );
        return;
      }
      if (this._state.unsupportedApis.includes('getMeetingParticipants')) {
        this.log.warn('Zoom SDK getMeetingParticipants is unsupported');
        return;
      }
      const resp = await this.sdk.getMeetingParticipants();
      this.log.info('Zoom SDK getMeetingParticipants', {
        num: resp.participants.length,
      });
      this._state.numOfParticipants = resp.participants.length;
    } catch (error) {
      this.log.error('Zoom SDK getMeetingParticipants failed', error);
    }
  }

  async startCollaborate() {
    try {
      await this.sdk.startCollaborate({ shareScreen: true });
    } catch (error) {
      this.log.error('Zoom SDK startCollaborate failed', error);
    }
  }

  async openUrl(url: string) {
    try {
      await this.sdk.openUrl({ url });
    } catch (error) {
      this.log.error('Zoom SDK openUrl failed', error);
    }
  }

  async expandApp(action: ExpandAppOptions['action']) {
    try {
      await this.sdk.expandApp({ action });
    } catch (error) {
      this.log.error('Zoom SDK expandApp failed', error);
    }
  }

  async launchAppInMeeting() {
    try {
      await this.sdk.launchAppInMeeting();
    } catch (error) {
      this.log.error('Zoom SDK launchAppInMeeting failed', error);
    }
  }

  private initialState(): State {
    return {
      inited: 0,
      numOfParticipants: 0,
      unsupportedApis: [],
    };
  }
}

export class MockZoomClient implements IZoomClient {
  private _state;
  constructor(state?: Partial<Omit<State, 'inited'>>) {
    this._state = markSnapshottable(
      proxy<State>({ ...this.initialState(), ...state })
    );
  }

  async init() {
    this._state.inited = Date.now();
  }
  authorize(_codeChallenge: string, _state?: string): Promise<void> {
    return Promise.resolve();
  }
  promptAuthorize(): Promise<void> {
    return Promise.resolve();
  }
  startCollaborate(): Promise<void> {
    return Promise.resolve();
  }
  openUrl(url: string): Promise<void> {
    window.open(url, '_blank');
    return Promise.resolve();
  }
  expandApp(_action: ExpandAppOptions['action']): Promise<void> {
    return Promise.resolve();
  }

  async launchAppInMeeting(): Promise<void> {
    return Promise.resolve();
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  private initialState(): State {
    return {
      inited: 0,
      numOfParticipants: 0,
      unsupportedApis: [],
    };
  }
}
